import { FC } from "react"
import { useSwiper } from "swiper/react"
import { StyledSwiperPages } from "@/components/Swiper/StyledSwiper"

const Pages: FC<{ page?: number }> = ({ page }) => {
  const swiper = useSwiper()

  const pagesCount = swiper?.slides?.length || 0

  if (pagesCount <= 0) {
    return null
  }

  return (
    <StyledSwiperPages>
      {(page || 0) + 1}&nbsp;из&nbsp;
      {pagesCount}
    </StyledSwiperPages>
  )
}

export { Pages }
