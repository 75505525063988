import { FC, memo } from "react"
import dynamic, { DynamicOptions } from "next/dynamic"
import type { DesktopProductsSliderPropsType } from "./Desktop"
import type { MobileProductsSliderPropsType } from "./Mobile"
import {
  SliderTitle,
  StyledProductsSlider,
  StyledProductsSliderInner,
} from "./StyledProductsSlider"
import type { ProductsSliderPropsType } from "./types"
import {
  PLACEMENT_ARROW,
  VARIANT_ARROW,
} from "@/components/Swiper/SwiperWrapper"
import { useWindowSize } from "@/hooks/windowSize"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"

const Mobile = dynamic((() =>
  import("./Mobile").then(
    (mod) => mod.Mobile,
  )) as DynamicOptions<MobileProductsSliderPropsType>)

const Desktop = dynamic((() =>
  import("./Desktop").then(
    (mod) => mod.Desktop,
  )) as DynamicOptions<DesktopProductsSliderPropsType>)

const getTitleText = (title: ProductsSliderPropsType["title"]) => {
  if (!!title && typeof title === "object" && "content" in title) {
    return title.content
  } else {
    return title
  }
}

const getTitleLeftPadding = (title: ProductsSliderPropsType["title"]) => {
  if (!!title && typeof title === "object" && "leftPadding" in title) {
    return title.leftPadding
  } else {
    return false
  }
}

export const ProductsSlider: FC<ProductsSliderPropsType> = memo(
  ({
    products,
    title,
    variantArrow = VARIANT_ARROW.ANGLE,
    placementArrow = PLACEMENT_ARROW.VERTICAL,
    disabledOnMobile = false,
    responsiveExtends,
    productImageConfig,
  }) => {
    const { width } = useWindowSize()

    if (!products.length) {
      return null
    }

    const isMobileWidth =
      disabledOnMobile &&
      width !== undefined &&
      width <= getBreakpointVal(breakpoints.sm)

    return (
      <StyledProductsSlider
        data-variant-arrow={variantArrow}
        data-placement-arrow={placementArrow}
      >
        <StyledProductsSliderInner>
          {title && (
            <SliderTitle data-left-padding={getTitleLeftPadding(title)}>
              {getTitleText(title)}
            </SliderTitle>
          )}

          {isMobileWidth ? (
            <Mobile products={products} />
          ) : (
            <Desktop
              responsiveExtends={responsiveExtends}
              variantArrow={variantArrow}
              placementArrow={placementArrow}
              products={products}
              productImageConfig={productImageConfig}
            />
          )}
        </StyledProductsSliderInner>
      </StyledProductsSlider>
    )
  },
)

ProductsSlider.displayName = "ProductsSlider"
