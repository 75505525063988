import { Slider } from "@/components/Products/WatchedRecently/Slider"
import { Container } from "@/styles/utils/StyledGrid"

export const WatchedRecentlySliderContainer = () => {
  return (
    <Container>
      <Slider />
    </Container>
  )
}

WatchedRecentlySliderContainer.displayName = "WatchedRecentlySliderContainer"
