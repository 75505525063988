import { FC, memo } from "react"
import { getBreakpointVal } from "../../../styles/utils/Utils"
import { breakpoints } from "../../../styles/utils/vars"
import { Typography } from "../../ui/Typography/Typography"
import { ProductsSlider } from "../Slider"
import { ProductType } from "../types"

export interface IWatchedCard {
  products: ProductType[]
  title: string
}

export const PureSlider: FC<IWatchedCard> = memo(({ products, title }) => {
  return (
    <ProductsSlider
      responsiveExtends={{
        [getBreakpointVal(breakpoints.lg)]: {
          slidesPerView: 5,
        },
        [getBreakpointVal(breakpoints.md)]: {
          slidesPerView: 3,
        },
        [getBreakpointVal(breakpoints.sm)]: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      }}
      products={products}
      title={<Typography variant={"h3"}>{title}</Typography>}
    />
  )
})

PureSlider.displayName = "PureSlider"
